import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Jobs, JobDetail } from "../../components/organisms/activity";
import { Login } from "../../components/organisms/auth";
import DashBoard from "../../components/organisms/dashboard";
import Campaign from "../../components/organisms/campaign";
import Blueprint from "../../components/organisms/blueprint";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import UsersPage from "../../components/organisms/users/UsersPage";
import RolePage from "../../components/organisms/users/RolesPage";

const routes = [
  { path: "/", component: DashBoard },
  { path: "/projects", component: Jobs },
  { path: "/projects/:jobId", component: JobDetail },
  { path: "/login", component: Login },
  { path: "/campaigns", component: Campaign },
  { path: "/blueprints", component: Blueprint },
  {path: "/users", component: UsersPage},
  {path: "/roles", component: RolePage},
];

const Ai = () => {
  const location = useLocation();
  const NotFound = () => {
    return (
      <div>
        <h2>404 Not Found</h2>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-grow">
        <Sidebar />

        <div className="flex-grow h-full min-h-screen bg-slate-100">
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
                exact={route.exact}
              />
            ))}
            <Route path="*" element={<NotFound />} />{" "}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Ai;
