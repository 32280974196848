import React, { useMemo } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip as PieTip } from "recharts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import DoneIcon from "@mui/icons-material/Done";
import { Tooltip } from "@mui/material";
import { AppConstants } from "../../utils/constants";

const { Completed, Failed, Created, InProgress } = AppConstants.PROJECT_STATUS;
const COLORS = ["#00C49F", "#FFBB28", "#FF8042", "#0088FE"];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className={`rounded-sm w-36 ml-16 bg-opacity-70 h-auto ${
          payload[0].payload.tooltip == "Fully Migrated"
            ? "bg-[#00C49F]"
            : payload[0].payload.tooltip == "Partially Migrated"
            ? "bg-[#FFBB28]"
            : "bg-[#FF8042]"
        }`}
      >
        <p className="label p-2 text-white">{`${payload[0].payload.tooltip} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const Graph = ({ status, rowData, xPosition = 18, yPosition = 22, width=75, height = 75 }) => {
  const {
    fully_migrated = 0,
    not_migrated = 1,
    partially_migrated = 0,
  } = rowData || {};
  const total_components = fully_migrated + not_migrated + partially_migrated;
  const percentage = total_components
    ? parseInt(fully_migrated / total_components * 100) + "%"
    : "-";
  const data = useMemo(() => {
    return [
      {
        name: `${fully_migrated ?? 0}`,
        value: fully_migrated,
        tooltip: "Fully Migrated",
      },
      {
        name: `${partially_migrated ?? 0}`,
        value: partially_migrated,
        tooltip: "Partially Migrated",
      },
      {
        name: `${not_migrated ?? 0}`,
        value: not_migrated,
        tooltip: "Not Migrated",
      },
    ];
  }, [rowData]);

  return (
    <PieChart className={`justify-center ${status === Failed && 'ml-5'}`} width={65} height={65}>
      {/* <Legend
      className="flex justify-end items-right"
        verticalAlign="middle"
        layout="vertical"
        iconSize={10}
        iconType="circle"
      /> */}
       {status === Completed && <><Pie
        data={data}
        cx={30}
        cy={30}
        innerRadius={20}
        outerRadius={30}
        paddingAngle={0}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            className="outline-none"
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>

      <PieTip content={CustomTooltip} /></>}
      {/* <Pie
        data={[{value: 100}]}
        cx={30}
        cy={30}
        innerRadius={20}
        outerRadius={30}
        paddingAngle={0}
        dataKey="value"
      >
        <Cell
        className="outline-none"
        fill={'#d32f2f'}></Cell>
      </Pie> */}
      <foreignObject
        x={xPosition}
        y={yPosition}
        textAnchor="middle"
        width={40}
        height={20}
        className=" flex justify-center text-center text-green-600"
      >
        {/* <Tooltip title={status} placement="top" arrow>
          {status === Completed && (
            <DoneIcon color="success" style={{ fontSize: 20 }} />
          )}
          {status === Failed && (
            <PriorityHighIcon color="error" style={{ fontSize: 20 }} />
          )}
        </Tooltip> */}
         {status === Completed && <Tooltip
          title={`Project migration: ${percentage}`}
          placement="top"
          arrow
        >
          {percentage}
        </Tooltip>}
        {status === Failed && (
          <PriorityHighIcon color="error" style={{ fontSize: 20 }} />
          )}
      </foreignObject>
    </PieChart>
  );
};

export default Graph;
