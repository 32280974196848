import React, { useEffect, useState, useMemo } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Link } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UserService from "../../../services/Userlistservice";
import Table from '../../../shared/components/modecules/Table';

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userService = new UserService();
        const data = await userService.getAllUsers();
        setUsers(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error("Failed to fetch users:", err);
        setUsers([]);
      }
    };

    fetchUsers();
  }, []);

  const handleClickOpen = (id) => {
    setUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUserId(null);
  };

  const handleConfirmDelete = () => {
    setUsers(users.filter(user => user.id !== userId));
    handleClose();
  };

  const columns = useMemo(
    () => [
      {
        key: "id",
        title: "ID",
        render: (value) => <div className="text-center">{value}</div>,
      },
      {
        key: "user_id",
        title: "User ID",
        render: (value) => <div className="text-center">{value}</div>,
      },
      {
        key: "name",
        title: "Name",
        render: (value) => <div className="text-center">{value || "N/A"}</div>,
      },
      {
        key: "actions",
        title: "Actions",
        render: (id) => (
          <div className="flex gap-2 justify-center text-left">
            <IconButton>
              <VisibilityIcon className="text-primary" />
            </IconButton>
            <IconButton>
              <EditIcon className="text-primary" />
            </IconButton>
            <IconButton className="text-error" onClick={() => handleClickOpen(id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">User List</h2>
      <Table
        data={users}
        columns={columns}
        tableclassName="w-full text-left border-separate border-spacing-y-[10px] sm:mt-2"
        tdClassName="px-6 py-0 border-b dark:border-darkmode-300 box w-40 rounded-l-none rounded-r-none border-x-0 first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
        thClassName="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 bg-primary text-white dark:bg-darkmode-600 first:rounded-l-[0.6rem] last:rounded-r-[0.6rem]"
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersPage;