import React, { useEffect, useRef, useState } from 'react';
import AppUtils from '../../../../utils/AppUtils';
import { Button, Link, Tooltip } from '@mui/material';
import { AppConstants } from '../../../../utils/constants';
import DownloadButton from './DownloadButton';
import SemiCircleProgress from '../../../../shared/components/atoms/SemiCircleProgress';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';

const appUtils = AppUtils;
const { PROJECT_STATUS } = AppConstants;

const StageCard = ({
  stage,
  onOutputClick = () => null,
  onInputClick = () => null,
  onResume,
  isResume,
  resumeLoading,
  onOutputDownload,
  inputDownloading,
  outputDownloading,
  onInputDownload,
  stageCount,
  children,
  currentStageNumber,
  setShowRerunDialogue,
  handleVersionChange,
  versions = [],
  selectedVersion = '',
  target
}) => {
  const {
    start_time,
    end_time,
    stage_name,
    stage_status,
    stage_output,
    stage_input,
    stage_id,
    ...rest
  } = stage || {};

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    // <div className="flex bg-white shadow-md p-4 rounded-md w-full border-b-2 border-secondary">
    //   <div>
    //     <div className="flex justify-between items-center mb-4">
    //       <div className="flex items-center">
    //         <h2 className="text-xl font-semibold mr-4 text-gray-700">
    //           {stage_name}
    //         </h2>
    //         <Tooltip arrow placement="right" title={stage_status ?? "Pending"}>
    //           <div
    //             className={`text-sm px-1 py-1 rounded-full ${appUtils.getStatusBgColor(
    //               stage_status
    //             )}`}
    //           />
    //         </Tooltip>
    //       </div>
    //       <div>
    //         {!resumeLoading && isResume && (
    //           <Button size="small" variant="outlined" onClick={onResume}>
    //             Resume
    //           </Button>
    //         )}
    //         {((resumeLoading && isResume) ||
    //           [PROJECT_STATUS.InProgress, "In progress"].includes(
    //             stage_status
    //           )) && (
    //           <SemiCircleProgress
    //             strokeWidth={10}
    //             diameter={90}
    //             percentage={33}
    //             showPercentValue
    //           />
    //           // <CircularSpinner height="h-5" width="w-8" spinnerHeight={6} />
    //         )}
    //       </div>
    //     </div>

    //     {end_time && (
    //       <p className="text-sm mr-4 uppercase">
    //         <span className="font-medium text-gray-500">Processing time:</span>{" "}
    //         <span className="text-gray-400">
    //           {appUtils.getTimeDiff(start_time, end_time)}
    //         </span>
    //       </p>
    //     )}

    //     <p className="text-sm text-gray-400 mr-4 uppercase">
    //       <span className="font-medium text-gray-500">Started: </span>
    //       {start_time ? appUtils.getTime(start_time) : "N/A"} |{" "}
    //       <span className="font-medium text-gray-500">Ended: </span>
    //       {end_time ? appUtils.getTime(end_time) : "N/A"}
    //     </p>

    //     <div className="flex gap-4 mt-4">
    //       {stage_input && (
    //         <>
    //           {stage_id > 2 || stage_input.type == "JSON" ? (
    //             <Button size="small" variant="outlined" onClick={onInputClick}>
    //               Show Input
    //             </Button>
    //           ) : (
    //             <DownloadButton
    //               btnText="Show Input"
    //               variant="outlined"
    //               onDownload={onInputDownload}
    //               downloading={inputDownloading}
    //             />
    //           )}
    //         </>
    //       )}

    //       {stage_output && (
    //         <>
    //           {stage_id <= stageCount - 1 ? (
    //             <Button size="small" variant="outlined" onClick={onOutputClick}>
    //               Show Output
    //             </Button>
    //           ) : (
    //             <DownloadButton
    //               btnText="Download"
    //               variant="outlined"
    //               onDownload={onOutputDownload}
    //               downloading={outputDownloading}
    //             />
    //           )}
    //         </>
    //       )}
    //     </div>
    //   </div>
    //   <div className="h-full w-52">{children}</div>
    // </div>
    <li className="ab cty cux">
      <div className="w-full">
        <div className="ma zr asp ath axo aya">
          <div className="ma nt sa vj zr zw aer agb ahk">
            <span className="azv ">{currentStageNumber + 1}</span>
          </div>
          <div className="kb axo aya azi text-primary text-lg font-medium">
            {stage_name}
          </div>

          <Tooltip arrow placement="right" title={stage_status ?? 'Pending'}>
            <div
              className={`text-sm ml-2 px-1 py-1 rounded-full ${appUtils.getStatusDarkBgColor(
                stage_status
              )}`}
            />
          </Tooltip>

          <div ref={dropdownRef} style={{ position: "relative", display: "inline-block" }}>
            <div
              onClick={() => setIsOpen(!isOpen)}
              style={{
                padding: "10px 15px",
                cursor: "pointer",
                userSelect: "none",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              className='text-xs text-[#34A853] mr-4 uppercase'
            >
              {selectedVersion}
              <span>{isOpen ? "▲" : "▼"}</span>
            </div>
            {isOpen && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  background: "white",
                  boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                  zIndex: 10,
                  width: "50%",
                }}
              >
                {versions.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      handleVersionChange(option, currentStageNumber);
                      setIsOpen(false);
                    }}
                    style={{
                      padding: "10px 15px",
                      cursor: "pointer",
                    }}
                    className={`${option === selectedVersion ? 'text-[#34A853]' : 'text-gray-700'}`}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>


        </div>
        <div className="in-block p-4">
          {((resumeLoading && isResume) ||
            [PROJECT_STATUS.InProgress, 'In progress'].includes(
              stage_status
            )) && (
              <SemiCircleProgress
                strokeWidth={10}
                diameter={90}
                percentage={33}
                showPercentValue
              />
              // <CircularSpinner height="h-5" width="w-8" spinnerHeight={6} />
            )}
          {/* <div className="mt-3 flex flex-col justify-between px-4 sm:flex-row sm:items-center sm:px-5">
            <div></div>
            <div className="ml-auto"></div>
          </div> */}
          <div className="mt-0 flex flex-col justify-between px-4 sm:flex-row sm:items-center sm:px-5">
            <p className="text-xs text-gray-400 mr-4 uppercase">
              <TodayOutlinedIcon fontSize="small" />
              {start_time ? appUtils.getTime(start_time) : 'N/A'} |{' '}
              <InsertInvitationOutlinedIcon fontSize="small" />
              {end_time ? appUtils.getTime(end_time) : 'N/A'}
              {end_time && (
                <span className="mr-4 ml-2 uppercase">
                  | <ScheduleOutlinedIcon fontSize="small" />
                  <span className="text-gray-400">
                    {appUtils.getTimeDiff(start_time, end_time)}
                  </span>
                </span>
              )}
            </p>
          </div>
          <div className="flex gap-4 mt-4">
            {!resumeLoading && isResume && (
              <Button size="small" variant="outlined" onClick={onResume}>
                Resume
              </Button>
            )}
            {stage_input && (
              <>
                {stage_id > 2 || stage_input.type == 'JSON' ? (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={onInputClick}
                  >
                    Show Input
                  </Button>
                ) : (
                  <DownloadButton
                    btnText="Show Input"
                    size="small"
                    variant="outlined"
                    onDownload={onInputDownload}
                    downloading={inputDownloading}
                  />
                )}
              </>
            )}

            {stage_output && (
              <>
                {stage_id <= stageCount - 1 ? (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={onOutputClick}
                  // className="btnSparkle"
                  >
                    Show Output
                  </Button>
                ) : target == 'mule'?
                  stage?.job_output && stage?.job_output != 'null' && <DownloadButton
                    btnText="Download"
                    variant="outlined"
                    onDownload={onOutputDownload}
                    downloading={outputDownloading}
                  />
                :(
                  <Link
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      window.open(
                        'https://platform.boomi.com',
                        '_blank',
                        'noreferrer'
                      )
                    }
                  >
                    <Button size="small" variant="outlined">
                      View in Boomi
                    </Button>
                  </Link>
                  
                )}
                
              </>
            )}
            {end_time && stage_id !== 1 && (
             <Button
              size="small"
              variant="outlined"
              onClick={() => { setShowRerunDialogue(true, currentStageNumber) }}
            >
              Rerun
            </Button>)}
          </div>
        </div>
        {/* <div className="h-full w-52">{children}</div> */}
      </div>
      {stageCount > currentStageNumber + 1 && (
        <div aria-hidden="true" className="aa dh dq mg pv st ctv">
          <svg
            fill="none"
            viewBox="0 0 22 80"
            preserveAspectRatio="none"
            className="pv uf azb"
          >
            <path
              d="M0 -2L20 40L0 82"
              stroke="currentcolor"
              vectorEffect="non-scaling-stroke"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      )}
    </li>
  );
};

export default StageCard;