import Table from '../../../shared/components/modecules/Table';
import Graph from '../../modecules/graph';

import AppUtils from '../../../utils/AppUtils';
import { AppConstants } from '../../../utils/constants';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Link,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';

const { Completed, Failed, Created, InProgress } = AppConstants.PROJECT_STATUS;
const ActivityTable = ({
  data,
  onRowViewClicked,
  hideActions = false,
  onDeleteJob,
}) => {
  const [open, setOpen] = useState(false);
  const [jobId, setJobId] = useState(null);

  const handleClickOpen = (id) => {
    setJobId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setJobId(null);
  };
  const handleConfirmDelete = () => {
    onDeleteJob(jobId);
    handleClose();
  };

  const columns = [
    {
      key: 'job_name',
      title: 'Name',
      render: (value, rowData) => (
        <Link
          component="button"
          variant="button"
          scope="row"
          underline={'hover'}
          className="text-left truncate text-ellipsis"
          onClick={() => onRowViewClicked(rowData.id)}
        >
          {value ?? 'JOB NAME'}
        </Link>
      ),
    },
    {
      key: 'job_type',
      title: 'Type',
      render: (value) => <div className="uppercase text-left ">{value}</div>,
    },
    {
      key: 'start_time',
      title: 'Start Date',
      render: (value) => (
        <div className=" text-left">{AppUtils.getFormattedDate(value)}</div>
      ),
    },
    {
      key: 'job_status',
      title: <div className="text-center">Status</div>,
      align: 'center',
      render: (value, rowData) => (
        <div className="flex justify-center items-center">
          {[Completed].includes(value) && (
            <Graph
              status={value}
              rowData={rowData}
              width={70}
              height={70}
            />
          )}
          {[Failed].includes(value) && (
            <div className="flex items-center justify-center">
            {/* <Graph
              status={value}
              rowData={rowData}
              width={70}
              height={70}
              className="inline-block align-top"
            /> */}
            <p className="text-left"><span style={{color: 'red', fontSize: 'medium', marginRight: '4px'}}>!</span>{value}</p>
            </div>
          )}

          {[InProgress, Created].includes(value) && (
            <div className="loader flex items-center gap-2">
              <div className="w-2 h-2 rounded-full moving-dot" />
              <p>{value}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'end_time',
      title: 'Time Elapsed',
      render: (value, { start_time }) => {
        if (value) {
          return (
            <div className="flex justify-left text-ellipsis">
              <div className="w-32 truncate text-left">
                {AppUtils.getTimeDiff(start_time, value)}
              </div>
            </div>
          );
        }
        return <div className="px-10 text-left">-</div>;
      },
    },
    ...(hideActions
      ? []
      : [
          {
            key: 'id',
            title: 'Actions',
            render: (id) => (
              <div className="flex gap-2 justify-center text-left border-l-2 min-w-32">
                <IconButton onClick={() => onRowViewClicked(id)}>
                  <VisibilityIcon className="text-primary" />
                </IconButton>
                <IconButton className="text-primary">
                  <EditIcon />
                </IconButton>
                <IconButton
                  className="text-error"
                  onClick={() => handleClickOpen(id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ),
          },
        ]),
  ];

  return (
    <div className="">
      <Table
        data={data}
        columns={columns}
        tableclassName="w-full text-left border-separate border-spacing-y-[10px] sm:mt-2"
        tdClassName="px-6 py-0 border-b dark:border-darkmode-300 box w-40 rounded-l-none rounded-r-none border-x-0 s first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600"
        thClassName="font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0 bg-primary text-white dark:bg-darkmode-600 first:rounded-l-[0.6rem] last:rounded-r-[0.6rem]"
        theadClassName=""
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this job?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActivityTable;
