import { IconButton, Tooltip, Typography } from "@mui/material";
import { Handle, Position } from "reactflow";
import AppUtils from "../../../utils/AppUtils";

const BasicNode = ({ data }) => {
  const { summary, activityType, name, final, start, configuration  } = data;
  const label = summary?.targetType || summary?.simType || name;
  const { icon: Icon, className } = AppUtils.getIcon(activityType.toLowerCase());
  return (
    <>
      {!start && (
        <Handle
          type="target"
          position={Position.Left}
          style={{
            border: "1px solid rgb(152, 152, 152)",
            backgroundColor: "white",
          }}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Tooltip title={activityType} arrow placement="top">
          <div>
            <IconButton>
              <Icon fontSize="large" className={className} />
            </IconButton>
          </div>
        </Tooltip>

        <Tooltip
          title={
            <div>
              {summary?.targetType && (
                <p className="mb-1">Target Type: {summary?.targetType}</p>
              )}
              {summary?.simType && (
                <p className="mb-1">Sim Type: {summary?.simType}</p>
              )}
              <p>Name: {name}</p>
              {configuration && (
                Object.keys(configuration).map((key)=>(
                  <p>{`${key}: ${configuration[key]}`}</p>
                ))
              )}
            </div>
          }
          arrow
          placement="top"
        >
          <Typography variant="body1" noWrap>
            {label}
          </Typography>
        </Tooltip>
      </div>

      {!final && (
        <Handle
          type="source"
          position={Position.Right}
          id="a"
          style={{
            border: "1px solid rgb(152, 152, 152)",
            backgroundColor: "white",
          }}
        />
      )}
    </>
  );
};

export default BasicNode;
