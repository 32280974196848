import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../../../utils/constants';
import { NotificationConstants } from '../../../utils/constants';
import {
  modifyStepsLabel,
  validProjectInput,
  validPromptInput,
} from '../../../utils';
import NavigationButtons from '../../modecules/NavigationButtons';
import Processing from '../../modecules/Processing';
import ProjectCreationWorkspace from './ProjectCreationWorkspace';
import StepperComponent from '../../stepper/Stepper';
import SourceSelection from './SourceSelection';
import ProjectVisualizer from './ProjectVisualizer';
import { JobService } from '../../../services';
import { useNotify } from '../../../shared/hooks';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './../../../assests/css/styles/dialog-styles.css';
import ReviewProject from './ReviewProject';
import TibcoIcon from '../../../assests/tibco_logo.png';
import BoomiLogo from "../../../assests/boomi_logo.png";

const initialSteps = [
  { label: 'Default project', step: 'Project' },
  { label: '7LB Modal', step: 'Input data' },
  { label: 'Review', step: 'Review Project' }
];

const MESSAGE = AppConstants.MESSAGE;
const NOTIFICATION_TYPE = NotificationConstants.NOTIFICATION_TYPE;
const PROJECT_PLATFORM = AppConstants.PROJECT_PLATFORM;
const { INPUT_TYPE } = AppConstants;

const ProjectSubmissionWizard = ({ initailPrompt = '', handleClose }) => {
  const jobService = new JobService();
  const [notify] = useNotify();

  const navigate = useNavigate();
  const [steps, setSteps] = useState(initialSteps);
  const [projectInput, setProjectInput] = useState({
    platform: AppConstants.PROJECT_PLATFORM.boomi,
    projectName: '',
    campaign: '',
    isError: '',
    customizations: { function_mapping: [] },
    targetOption: {
      title: "Boomi",
      subtitle:
        "Integration platform for connecting applications, data, and devices",
      imageSrc: BoomiLogo,
      disabled: false,
      value: PROJECT_PLATFORM.boomi,
    }
  });

  const [promptInput, setPromptInput] = useState({
    type: AppConstants.INPUT_TYPE.shipZip,
    file: '',
    text: 'Create boomi project that has http service and returns hello world',
    isError: '',
    sourcePlatform: 'tibco',
    targetPlatform: 'boomi',
    sourceOption: {
      title: 'Upload a Tibco project',
      subtitle:
        'Choose this option if you have a BusinessWorks project ready to upload',
      value: INPUT_TYPE.shipZip,
      src: TibcoIcon,
      sourcePlatform: 'tibco',
      platform: PROJECT_PLATFORM.tibco
    }
  });

  const [step, setStep] = useState(0);
  const [stepOneOutput, setStepOneOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [jobId, setJobId] = useState(null);

  const handleNext = async () => {
    if (step === 0) {
      if (validProjectInput(projectInput, setProjectInput, notify)) {
        setSteps(modifyStepsLabel(step, projectInput.campaign.name, steps));
        setStep((pre) => pre + 1);
      }
      return;
    }
    if(step ===1){
      setStep(2);
    }
    // if (step === 2) {
    //   if (validPromptInput(promptInput, setPromptInput, notify)) {
    //     try {
    //       setIsLoading(true);
    //       const jobRes = await jobService.createJobWithPreview(
    //         promptInput,
    //         projectInput
    //       );
    //       const stages = await jobService.getAllStagesOfJob(jobRes.id);
    //       setJobId(jobRes.id);
    //       const response = JSON.parse(stages[0].output_content);
    //       if (typeof response == 'string') {
    //         notify(
    //           true,
    //           MESSAGE.notEnoughInputs,
    //           NOTIFICATION_TYPE.error,
    //           5000
    //         );
    //         return;
    //       }
    //       setStepOneOutput(response);
    //       // setStep(2);
    //       // setSteps(modifyStepsLabel(step, promptInput.type, steps));

    //       setStep(3);
    //       await jobService.resumeJobById(jobId);
    //     } catch (e) {
    //       notify(true, e.message, NOTIFICATION_TYPE.error, 5000);
    //     } finally {
    //       setIsLoading(false);
    //     }
    //   }
    //   return;
    // }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleCloseModal = () => {
    navigate(`/projects/${jobId}`);
  };
  const handleProjectSubmission = async (project) => {
    try {
      // setStep(3);
      // await jobService.resumeJobById(jobId);

      if (validPromptInput(promptInput, setPromptInput, notify)) {
        try {
          setIsLoading(true);
          const jobRes = await jobService.createJobWithPreview(
            promptInput,
            projectInput
          );
          setJobId(jobRes?.id);
          setStep(3);
          await jobService.resumeJobById(jobRes?.id);
        } catch (e) {
          notify(true, e.message, NOTIFICATION_TYPE.error, 5000);
        } finally {
          setIsLoading(false);
        }
      }
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    }
  };

  const hansleSave = async () =>{
    try {
    setIsLoading(true)
    const jobRes = await jobService.createJobWithPreview(
      promptInput,
      projectInput,
      false
    );
    if(jobRes?.id){
      navigate(`/projects/${jobRes?.id}`);
    }else{
      handleClose();
    }

  } catch (e) {
    notify(true, e.message, NOTIFICATION_TYPE.error, 5000);
  } finally {
    setIsLoading(false);
  }
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="lg"
        className="dialog-rainbow-border"
      >
        {step < 3 && (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Create a project
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
        <DialogContent dividers>
          {step < 3 && <StepperComponent activeStep={step} steps={steps} />}
          <div className="my-4 overflow-y-auto h-4/5 w-full">
            {step === 0 && (
              <ProjectCreationWorkspace
                setProjectInput={setProjectInput}
                projectInput={projectInput}
              />
            )}
            {step === 1 && (
              <SourceSelection
                setPromptInput={setPromptInput}
                promptInput={promptInput}
                disabled={isLoading}
              />
            )}
            {
              step === 2 && (
                <ReviewProject 
                  setPromptInput={setPromptInput}
                  setProjectInput={setProjectInput}
                  promptInput={promptInput}
                  projectInput={projectInput}
                />
              )
            }
            {/* {step === 3 && (
              <ProjectVisualizer
                stepOneOutput={stepOneOutput}
                setStepOneOutput={setStepOneOutput}
              />
            )} */}
            {step === 3 && (
              <Processing
                handleClose={handleCloseModal}
                projectName={projectInput.campaign.name}
              />
            )}
          </div>
        </DialogContent>
        {step < 3 && (
          <DialogActions>
            <NavigationButtons
              activeStep={step}
              totalStep={steps.length}
              isLoading={isLoading}
              handleBack={handleBack}
              handleNext={handleNext}
              handleDone={handleProjectSubmission}
              hansleSave={hansleSave}
              doneBtnLabel="Submit"
            />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ProjectSubmissionWizard;
