import { Button, Link } from "@mui/material";
import TableSkelton from "../../../shared/components/modecules/TableSkelton";
import Table from "../../../shared/components/modecules/Table";
import EditIcon from "@mui/icons-material/Edit";

const CampaignList = ({
  loading = false,
  data = [],
  onRowViewClicked = () => null,
  hideActions = false,
  onEditClicked = (id, rowDate) => null,
}) => {
  const columns = [
    // {
    //   key: "id",
    //   title: "Id",
    //   render: (value, rowData) => (
    //     <Link
    //       component="button"
    //       variant="button"
    //       scope="row"
    //       underline={"hover"}
    //       className="px-6"
    //       onClick={() => onRowViewClicked(value, rowData)}
    //     >
    //       {value}
    //     </Link>
    //   ),
    // },
    {
      key: "name",
      title: "Name",
      render: (value, rowData) => (
        <Link
          component="button"
          variant="button"
          scope="row"
          underline={"hover"}
          className="px-6"
          onClick={() => onRowViewClicked(rowData?.id, rowData)}
        >
          {value}
        </Link>
      ),
    },
    {
      key: "jobs",
      title: "Projects",
    },
    ...(hideActions
      ? []
      : [
          {
            key: "id",
            title: "Actions",
            render: (id, rowData) => (
              <>
                <Button
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => onEditClicked(id, rowData)}
                >
                  Edit
                </Button>
              </>
            ),
          },
        ]),
  ];
  if (loading) {
    return <TableSkelton rows={8} />;
  }
  return (
    <div className="shadow-md bg-white mt-6">
      <Table data={data} tdClassName="p-5 text-center"
      thClassName="p-3 first:rounded-l-[0.6rem] last:rounded-r-[0.6rem]" columns={columns} />
    </div>
  );
};

export default CampaignList;
