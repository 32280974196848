import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

const AccordionList = [
  {
    name: "function_mapping",
    title: "Function Mapping",
    description:
      "Customize how functions interact with databases and configure Boomi applications for precise database operations.",
  },
  // {
  //   name: "category_mapping",
  //   title: "Category Mapping",
  //   description:
  //     "Define mappings between JDBC queries and database operations and specify actions to be performed on databases based on specific queries.",
  // },
  // {
  //   name: "config_mapping",
  //   title: "Config Mapping",
  //   description:
  //     "Configure application settings and properties and set up network communication and other application parameters.",
  // },
];

const CustomizationPanel = ({ customizations, setFormData, customTitle }) => {
  const [expanded, setExpanded] = React.useState(customTitle);

  const handleAddNewPair = (name) => {
    setFormData((pre) => ({
      ...pre,
      customizations: {
        ...pre.customizations,
        [name]: [...customizations[name], { key: "", value: "" }],
      },
    }));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleRemovePair = (name, index) => {
    const updatedPairs = customizations[name].filter((_, idx) => index !== idx);
    setFormData((prev) => ({
      ...prev,
      customizations: { ...prev.customizations, [name]: updatedPairs },
    }));
  };

  const handleInputChange = (name, index, field, value) => {
    const updatedPairs = [...customizations[name]];
    updatedPairs[index][field] = value;
    setFormData((prev) => ({
      ...prev,
      customizations: { ...prev.customizations, [name]: updatedPairs },
    }));
  };

  return (
    <div>
      {AccordionList.map(({ name, title, description }) => (
        <Accordion
          key={name}
          expanded={expanded === (customTitle || name)}
          onChange={handleChange(customTitle || name)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${name}-content`}
            id={`${name}-header`}
          >
            <Typography>{customTitle || title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: "text.secondary" }} className="mb-2">
              {description}
            </Typography>

            {customizations[name].map((field, index) => (
              <div key={`${name}-${index}`} className="flex gap-4">
                <TextField
                  required
                  value={field.key ?? ""}
                  onChange={(e) =>
                    handleInputChange(name, index, "key", e.target.value)
                  }
                  margin="dense"
                  // name="key"
                  label="Key"
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <TextField
                  required
                  value={field.value ?? ""}
                  onChange={(e) =>
                    handleInputChange(name, index, "value", e.target.value)
                  }
                  margin="dense"
                  // name="value"
                  label="Value"
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <div className="flex items-center">
                  <IconButton onClick={() => handleRemovePair(name, index)}>
                    <ClearIcon />
                  </IconButton>
                </div>
              </div>
            ))}

            <Button
              variant="text"
              startIcon={<AddIcon />}
              onClick={() => handleAddNewPair(name)}
            >
              Add
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default CustomizationPanel;
