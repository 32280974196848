import React from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  if (totalPages <= 1) return null;

  const generatePageNumbers = () => {
    const pages = [];

    // Always include first page
    pages.push(1);

    // Show ellipsis if needed
    if (currentPage > 4) pages.push("...");

    // Generate dynamic middle pages
    let startPage = Math.max(2, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Show ellipsis before last page if needed
    if (currentPage < totalPages - 3) pages.push("...");

    // Always include last page (if more than 1)
    if (totalPages > 1) pages.push(totalPages);

    return pages;
  };

  const pages = generatePageNumbers();

  return (
    <div className="flex gap-2 mt-4">
      <button 
        className="rounded-md px-3 py-1 cursor-pointer hover:bg-secondary-200 bg-white shadow-md" 
        onClick={() => onPageChange(currentPage - 1)} 
        disabled={currentPage === 1}
      >
        Prev
      </button>

      {pages.map((page, index) =>
        page === "..." ? (
          <span key={index} className="px-2">
            ...
          </span>
        ) : (
          <button
            key={index}
            className={`rounded-md px-3 py-1 cursor-pointer hover:bg-secondary-200  ${
                page === currentPage
                  ? 'bg-primary text-white shadow-lg'
                  : 'bg-white shadow-md'
              }`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        )
      )}

      <button 
        className="rounded-md px-3 py-1 cursor-pointer hover:bg-secondary-200 bg-white shadow-md" 
        onClick={() => onPageChange(currentPage + 1)} 
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;