import { useEffect } from 'react';
import { useGet } from '../../../../shared/hooks';
import { JobService } from '../../../../services';
import TableSkelton from '../../../../shared/components/modecules/TableSkelton';
import Graph from '../../../modecules/graph';
import AppUtils from '../../../../utils/AppUtils';
import { CircularProgress, Link } from '@mui/material';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const RecentProjects = ({ handleNavigation }) => {
  const { fetchData, data, loading } = useGet();

  useEffect(() => {
    fetchData(new JobService().getJobsUrl(1, 4));
  }, []);

  const onRowViewClicked = (id) => {
    handleNavigation(`/projects/${id}`);
  };

  if (loading) return <TableSkelton columns={6} rows={8} />;

  // return (
  //   <ActivityTable
  //     data={data?.records || []}
  //     onRowViewClicked={onRowViewClicked}
  //   />
  // );

  return (
    <div className="gap-4 grid grid-cols-4">
      {data?.records?.map((rowData) => {
  if (!rowData) return null;
  const {
    id,
    job_status: status,
    job_name: name,
    start_time,
    end_time,
  } = rowData;

  const statusBgColor = AppUtils.getStatusBgColor(status);
  const statusTextColor = AppUtils.getStatusTextColor(status);

  const defaultName = "JOB NAME";
  const maxChars = 12;
  const upperCaseName = name && name.trim() ? name.toUpperCase() : defaultName;
  const truncatedName = upperCaseName.length > maxChars
    ? upperCaseName.substring(0, maxChars) + '...'
    : upperCaseName;

  return (
    <div key={id} className="rounded-lg shadow-md items-center justify-center px-2 py-4 bg-white">
      <div className="flex justify-center gap-2 flex-col text-center align-middle">
        <div className="flex items-start w-full">
          <Link
            component="button"
            variant="button"
            scope="row"
            underline={'hover'}
            color="inherit"
            className="px-6 text-left truncate text-ellipsis flex-none text-left mr-5 text-blue"
            onClick={() => onRowViewClicked(id)}
          >
            {truncatedName}
          </Link>
          <div className={`${statusBgColor} rounded-lg flex justify-center items-center p-1 mb-1 ml-auto`}>
            <p className={`text-sm px-2 font-medium ${statusTextColor}`}>
              {status}
            </p>
          </div>
        </div>

        {['Completed', 'Failed'].includes(status) ? (
          <div className="flex justify-center items-center">
            <Graph status={status} rowData={rowData} xPosition={16} yPosition={23} />
          </div>
        ) : (
          <div className="h-30 py-2">
            <CircularProgress style={{ color: '#00C49F' }} size={45} thickness={6.5} />
          </div>
        )}

        <div className="flex w-full justify-between gap-6">
          <p className="text-sm text-slate-500 ">
            <span className="font-medium text-gray-500">
              <EventAvailableOutlinedIcon />{' '}
            </span>
            {start_time ? AppUtils.getTime(start_time) : 'N/A'}
          </p>
          <p className="text-sm text-slate-500 truncate text-ellipsis  max-w-48">
            <span className="font-medium text-gray-500">
              <AccessTimeIcon />{' '}
            </span>
            {end_time ? AppUtils.getTimeDiff(start_time, end_time) : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
})}
    </div>
  );
};

export default RecentProjects;
