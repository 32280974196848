import React from "react";
import CircularSpinner from "./spinner";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
const RadioButtonWithSubtitle = ({
  title,
  subtitle,
  isChecked,
  onChange,
  children,
  disabled,
  loading = false,
  iconHoverText,
  imageSrc,
  showCheckBox = true,
  styles = {}
}) => {
  return (
    <label className="block">
      <div
        className={`border rounded-md p-4 mb-2 relative ${
          isChecked
            ? "border-secondary-300 bg-secondary-50 hover:bg-secondary-100"
            : "border-gray-200 hover:bg-gray-100"
        } 
             ${disabled ? "bg-gray-100" : ""}`}
        style={styles}
      >
        <div className="flex items-start gap-2">
          {showCheckBox && <div className="mr-4 mt-1">
            <input
              type="radio"
              checked={isChecked}
              onChange={onChange}
              disabled={disabled}
              className="h-4 w-4 accent-primary"
            />
          </div>}
          <div className="flex-1">
            <p
              className={`text-sm font-semibold leading-5 ${
                disabled ? "text-gray-400" : "text-gray-900"
              }`}
            >
              {title}
            </p>
            <p
              className={`text-sm ${
                disabled ? "text-gray-400" : "text-gray-600"
              }`}
            >
              {subtitle}
            </p>
          </div>
          {imageSrc && (
            <img
              src={imageSrc}
              width={40}
              height={40}
              className={disabled && "opacity-50"}
            />
          )}
          {iconHoverText && (
            <Tooltip title={iconHoverText}>
              <IconButton>
                <InfoIcon className="text-secondary-600" />
              </IconButton>
            </Tooltip>
          )}
          {loading && (
            <CircularSpinner height="h-5" width="w-8" spinnerHeight={6} />
          )}
        </div>

        {children && <div className="mt-2 ml-2 flex-1">{children}</div>}
      </div>
    </label>
  );
};

export default RadioButtonWithSubtitle;
