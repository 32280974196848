import { useState } from "react";

import DropdownWithAutoSuggestion from "../../atoms/DropdownWithAutoSuggestion";
import CustomizationPanel from "../campaign/CustomizationPanel";
import { ProjectService } from "../../../services";
import { useGet } from "../../../shared/hooks";
import RadioButtonWithSubtitle from "../../atoms/radio";
import { Tooltip } from "@mui/material";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { mapCustomization } from "../../../utils";
import CreateCampaign from "../campaign/CreateCampaign";

const projectService = new ProjectService();

const ReviewProject = ({ promptInput, projectInput, setPromptInput, setProjectInput }) => {
    const { file } = promptInput || {};
    const { fetchData, data: campaigns } = useGet();
    const { fetchData: fetchCampaignById } = useGet();
    const [modalOpen, setModalOpen] = useState(false);

    // useEffect(async () => { await getAllProjects(); }, [])

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setPromptInput((prev) => ({
            ...prev,
            file: selectedFile,
        }));
    };


    const getCampaignById = async (id) => {
        const resp = await fetchCampaignById(projectService.projectByIdUrl(id));
        let custArr = [];
        try {
            custArr = mapCustomization(resp.customization);
        } catch (e) {
            // customization could be null or error while parsing
        }
        return { ...resp, customizations: { function_mapping: custArr } };
    };

    const handleCampaignChange = async (option) => {
        if (option === "ADD_NEW") {
            setProjectInput((prevInput) => ({
                ...prevInput,
                campaign: "",
                isError: "",
            }));
            toggleModal();
            return;
        }
        const { id } = option;
        const resp = await getCampaignById(id);
        setProjectInput((prevInput) => ({
            ...prevInput,
            campaign: JSON.parse(JSON.stringify(resp)), // deep copy
            customizations: resp.customizations,
            isError: "",
        }));
    };

    const handleProjectNameChange = (e) => {
        setProjectInput((prevInput) => ({
            ...prevInput,
            projectName: e.target.value,
        }));
    };
    const getAllProjects = async () => {
        await fetchData(projectService.getProjectsUrl(1, 500));
    };
    const onCampaignCreated = async (campaign) => {
        handleCampaignChange(campaign);
        await getAllProjects();
    };

    const toggleModal = () => {
        setModalOpen((pre) => !pre);
    };
    return (
        <div className="bg-white shadow-md p-6 rounded-md">
            <div className="flex flex-col">
                <div className="flex mb-3 w-full">
                    <input
                        type="text"
                        placeholder="Enter project name"
                        className={`w-1/2 cursor-default h-11 bg-white py-2.5 pl-3 pr-10 text-left text-gray-900 shadow-sm border-0 border-b-2 border-black   sm:text-sm sm:leading-6`}
                        value={projectInput.projectName}
                        onChange={handleProjectNameChange}
                    />
                    <DropdownWithAutoSuggestion
                        options={campaigns?.records || []}
                        placeholder="Select a team"
                        labelKey="name"
                        onSelect={handleCampaignChange}
                        selectedValue={projectInput?.campaign}
                        style={{flexGrow: 1}}
                    />
                </div>

                <div className="flex gap-3">
                    <div>
                        <p className="mb-2"><strong>Selected Source:</strong></p>
                        <RadioButtonWithSubtitle
                            imageSrc={promptInput?.sourceOption?.src}
                            key={promptInput?.sourceOption?.value + promptInput?.sourceOption?.sourcePlatform}
                            title={promptInput?.sourceOption?.title}
                            subtitle={promptInput?.sourceOption?.subtitle}
                            isChecked={true}
                            disabled={false}
                            loading={false}
                            iconHoverText={promptInput?.sourceOption?.iconHoverText}
                            showCheckBox={false}
                        >
                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2">
                            <div className="text-center">
                                <div className="mt-2 text-sm leading-6 text-gray-600">
                                    <label
                                        for="file-upload"
                                        className="relative cursor-pointer rounded-md font-semibold text-secondary-600 focus-within:outline-none hover:text-secondary-500"
                                    >
                                        <Tooltip title="Select a file" arrow>
                                            <FolderZipIcon
                                                style={{ fontSize: 40 }}
                                                className="text-secondary-500"
                                            />

                                            <br />
                                            {file && (
                                                <p className="text-gray-700 font-normal">
                                                    {file?.name}
                                                </p>
                                            )}
                                            <span>Upload a zip file</span>
                                            <input
                                                id="file-upload"
                                                name="file-upload"
                                                type="file"
                                                className="hidden"
                                                onChange={handleFileChange}
                                                accept=".zip,.rar,.7zip"
                                            />
                                        </Tooltip>
                                    </label>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">
                                    Click the button to select a local file, or drag and drop
                                    into the upload window
                                </p>
                            </div>
                        </div>
                        </RadioButtonWithSubtitle>
                    </div>
                    <div>
                        <p className="mb-2"><strong>Selected Target:</strong></p>
                        <RadioButtonWithSubtitle
                            imageSrc={projectInput?.targetOption?.imageSrc}
                            title={projectInput?.targetOption?.title}
                            subtitle={projectInput?.targetOption?.subtitle}
                            isChecked={true}
                            disabled={false}
                            value={projectInput?.targetOption?.value}
                            showCheckBox={false}
                        />
                    </div>

                </div>
                {projectInput.campaign && (<div className="mt-5">
                    <CustomizationPanel
                        customizations={projectInput?.customizations}
                        setFormData={setProjectInput}
                        customTitle="Customizations"
                    />
                </div>
            )}
                
            </div>
            {modalOpen && (
                <CreateCampaign
                    onCampaignCreated={onCampaignCreated}
                    handleClose={toggleModal}
                />
            )}
        </div>
    );
};

export default ReviewProject;
