import { useState } from "react";
import axiosInstance from "../../services/AxiosService";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";

const useGet = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchData = async (url) => {
    setLoading(true);
    try {
      const resp = await axiosInstance.get(url);
      setData(resp.data);
      return resp.data;
    } catch (error) {
      setError(error);
      if (error.response && error.response.status === 401) {
        const authService = new AuthService();
        authService.logout();
        const { pathname } = window.location;
        if (pathname === "/") {
          navigate(`/login`);
          return
        }else if(!pathname.includes('login')){
          navigate(`/login?next=${pathname}`);
        }
        return
      }
    } finally {
      setLoading(false);
    }
  };
  return { fetchData, loading, data, error };
};

export default useGet;
