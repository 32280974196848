import { useEffect, useState } from "react";
import { useParams } from "react-router";
import CircularSpinner from "../../../atoms/spinner";
import ActivityDetailHeader from "../ActivityDetailHeader";
import { NotificationConstants } from "../../../../utils/constants";
import ActivityDetailSkeleton, {
  StepperSkeleton,
} from "../../../modecules/skelton";
import { JobService } from "../../../../services";
import { useGet, useNotify } from "../../../../shared/hooks";
// import Card from './Card';
import StageCard from "./StageCard";
import SummaryCard from "./SummaryCard";
import StageInputOutput from "./StageInputOutput";
import AppUtils from "../../../../utils/AppUtils";
import ActivitiesTypesCount from "./tables/ActivitiesTypeCount";
import MetricsCard from "./MetricsCard";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import MemoryIcon from "@mui/icons-material/Memory";
import ActivitiesAndFlows from "./ActivitiesAndFlows";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
const NOTIFICATION_TYPE = NotificationConstants.NOTIFICATION_TYPE;

const JobDetail = () => {
  const jobService = new JobService();
  const { fetchData, data, loading: loadingStats, error } = useGet();
  const [notify] = useNotify();
  const { jobId } = useParams();
  const [jobDetail, setJobDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [jobStages, setJobStages] = useState([]);
  const [currentStepDigrams, setCurrentStepDigrams] = useState(0);
  const [isInputOutputVisible, setIsInputOutputVisible] = useState(false);
  const [activeBtnType, setActiveBtnType] = useState();
  const [resumeIndex, setResumeIndex] = useState(-1);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [inputDownloading, setInputDownloading] = useState(false);
  const [outputDownloading, setOutputDownloading] = useState(false);
  const [showRerunDialogue, setShowRerunDialogue] = useState(false)
  const [legasyStageResp, setLegacyStageResp] = useState([])
  const [stageVersions, setStageVersions] = useState([
    {
      versions:['V1'],
      selectedVersion: 'V1'
    },
    {
      versions:['V1'],
      selectedVersion: 'V1'
    },
    {
      versions:['V1'],
      selectedVersion: 'V1'
    }
  ])
  const [rerunStage, setRerunStage] = useState();
  const [runs, setRuns] = useState();

  const getJobDetail = async () => {
    const resp = await jobService.getJobById(jobId);
    console.log( "resp", resp)
    setJobDetail(resp);
    return resp;
  };

  const findResumeIndex = async (stages = []) => {
    let index = -1;
    stages?.some((stage, idx) => {
      const status = stage?.stage_status;
      if (status && ["In progress", "In Progress", "Failed"].includes(status)) {
        return true;
      }
      if (!status) {
        index = idx;
        return true;
      }
      return false;
    });
    setResumeIndex(index);
  };

  const modifyRunsToStage = (data, version, stageResp, jobData) => {
    console.log("data of activity", data, stageResp, jobDetail)
    let stages =[]
    if(data && data?.run_details){
      data?.run_details.map(async (stage, idx)=>{
          let stageKey = `stage${idx+1}`
          let versionSet =new Set();
          let doPicLatest = true;
          if(version && (parseInt(version.replaceAll('V',''))>stage[stageKey]?.latest_version)){
            doPicLatest = false;
          }
          stage[stageKey]?.runs.map(async (run)=>{
              if(((version && doPicLatest)? `V${run?.version}`: run.version)==((version && doPicLatest)? version : stage[stageKey]?.latest_version)){
                let tempRun = {...run,
                end_time: run?.end_date_time,
                start_time: run?.start_date_time,
                stage_name: stageResp[idx].stage_name}

                if(stageKey === 'stage1'){
                  stages.push({
                    ...tempRun,
                    input_content:"",
                    output_content: JSON.stringify(run.stage_output),
                    stage_output:{
                      type: "JSON"
                    },
                  })
                }else{
                    stages.push({
                      ...tempRun,
                      output_content:JSON.stringify(run.stage_output),
                      input_content: JSON.stringify(run.stage_input),
                      stage_input:{
                        type: "JSON"
                      },
                      stage_output:{
                        type: "JSON"
                      },
                    })
                }
                if(stageKey === 'stage2'){
                  let summeryObj = AppUtils.camelToSnakeKeys(run?.stage_output?.projectInformation?.summary);
                  setJobDetail((prev)=>({
                    ...prev,
                    ...summeryObj
                  }))
                }
                
              }
              versionSet.add(`V${run?.version}`)
          })
          stageVersions[idx].versions = [...versionSet]
          stageVersions[idx].selectedVersion = (version && stageVersions[idx].versions.includes(version)) ? version :`V${stage[stageKey]?.latest_version}`
          setStageVersions(stageVersions)
      })
    }
    if(stages.length ===0){
      return [
        {
            "job_type": "Migration",
            "stage_id": 1,
            "stage_name": "Extract Tibco Config"
        },
        {
            "job_type": "Migration",
            "stage_id": 2,
            "stage_name": "Convert Tibco Config to SIM and Code Snippets"
        },
        {
            "job_type": "Migration",
            "stage_id": 3,
            "stage_name": (jobData?.targetPlatform ==="mule")?"Create Mule Project":"Create Boomi Project"
        }
      ]
    }else if(stages.length ==1){
      return [
        ...stages,
        {
            "job_type": "Migration",
            "stage_id": 2,
            "stage_name": "Convert Tibco Config to SIM and Code Snippets"
        },
        {
            "job_type": "Migration",
            "stage_id": 3,
            "stage_name": (jobData?.targetPlatform ==="mule")?"Create Mule Project":"Create Boomi Project"
        }
      ]
    }else if(stages.length ==2){
      return [
        ...stages,
        {
            "job_type": "Migration",
            "stage_id": 3,
            "stage_name": jobData?.targetPlatform ==="mule"?"Create Mule Project":"Create Boomi Project"
        }
      ]
    }
    return stages.length !==0 ? stages: [
            {
                "job_type": "Migration",
                "stage_id": 1,
                "stage_name": "Extract Tibco BW6 Config"
            },
            {
                "job_type": "Migration",
                "stage_id": 2,
                "stage_name": "Convert Tibco BW6 Config to SIM and Code Snippets"
            },
            {
                "job_type": "Migration",
                "stage_id": 3,
                "stage_name": jobData?.targetPlatform ==="mule"?"Create Mule Project":"Create Boomi Project"
            }
        ] 
  }

  const addConfig = async (data, version) =>{
    const config = await jobService.getStageConfiguration(jobId, version);
    console.log("config", config)
    return config.config_details ? {
        ...data,
      flows: data.flows.map((flow)=>{
          return {
            ...flow,
            components: flow.components.map((component)=>{
              if((component.sequenceId.toString()) in config.config_details){
                return {
                  ...component,
                  configuration: config.config_details[component.sequenceId.toString()]
                }
              }else{
                return component
              }
          })
        }
      })
    } : data
  }


  const activityStatus = async (data, jobId) => {
    try {
      console.log("Starting activityStatus function with jobId:", jobId);
      console.log("Initial data structure:", JSON.stringify(data, null, 2).substring(0, 500) + "...");
      
      const migrationStatusResponse = await jobService.getActivityStatus(jobId);
      console.log("Migration Status Response received:", 
        JSON.stringify(migrationStatusResponse, null, 2).substring(0, 500) + "...");
      
      if (!migrationStatusResponse || !Array.isArray(migrationStatusResponse.flows)) {
        console.warn("Invalid or missing migration status data, returning original data.");
        return data;
      }
      
      // Create a lookup map for faster access
      const statusMap = {};
      console.log("Building status map from response...");
      migrationStatusResponse.flows.forEach(flow => {
        statusMap[flow.name] = {};
        flow.components.forEach(comp => {
          statusMap[flow.name][comp.name] = comp.migrationStatus;
          console.log(`Added status for ${flow.name} -> ${comp.name}: ${comp.migrationStatus}`);
        });
      });
      
      console.log("Status map built:", JSON.stringify(statusMap, null, 2));
      
      // Update the data with migration statuses
      if (data.flows) {
        console.log("Updating flows in data object...");
        data.flows.forEach(flow => {
          const flowName = flow.name;
          console.log(`Processing flow: ${flowName}`);
          
          if (statusMap[flowName]) {
            flow.components.forEach(component => {
              const componentName = component.name;
              console.log(`  Processing component: ${componentName}`);
              
              if (statusMap[flowName][componentName]) {
                console.log(`    Updating ${componentName} in ${flowName}: ${statusMap[flowName][componentName]}`);
                component.migrationStatus = statusMap[flowName][componentName];
              } else {
                console.log(`    No status found for ${componentName} in ${flowName}, keeping original status: ${component.migrationStatus || "None"}`);
              }
            });
          } else {
            console.log(`  No status map found for flow: ${flowName}`);
          }
        });
      } else {
        console.log("No 'flows' property found in data object, checking for direct components...");
        // If your data structure is different and doesn't have flows
        data.forEach((stage, stageIndex) => {
          console.log(`Processing stage ${stageIndex + 1}`);
          if (stage && stage.flows) {
            console.log(`  Stage has flows property, updating...`);
            stage.flows.forEach(flow => {
              const flowName = flow.name;
              console.log(`    Processing flow: ${flowName}`);
              if (statusMap[flowName]) {
                flow.components.forEach(component => {
                  const componentName = component.name;
                  console.log(`      Processing component: ${componentName}`);
                  if (statusMap[flowName][componentName]) {
                    console.log(`        Updating ${componentName} status to: ${statusMap[flowName][componentName]}`);
                    component.migrationStatus = statusMap[flowName][componentName];
                  }
                });
              }
            });
          }
        });
      }
      
      console.log("Migration statuses updated successfully");
      console.log("Sample of updated data:", JSON.stringify(data, null, 2).substring(0, 500) + "...");
      
      return data;
    } catch (error) {
      console.error("Error fetching or processing migration status:", error);
      console.error("Error details:", error.stack);
      return data; // Return original data in case of error
    }
  };

  const getJobStages = async (jobData) => {
    try {
      const resp = await jobService.getAllStagesOfJob(jobId);
      setLegacyStageResp(resp);
      const runsResp = await jobService.getAllRunsOfJob(jobId);
      setRuns(runsResp);
      const modifiedRunResp = modifyRunsToStage(runsResp, undefined, resp, jobData);

      if(modifiedRunResp && modifiedRunResp[2] && modifiedRunResp[2].input_content){
        modifiedRunResp[2].input_content = JSON.stringify(await addConfig(JSON.parse(modifiedRunResp[2].input_content), runsResp.run_details[2]['stage3'].latest_version));
      }
      const updatedData = await activityStatus(modifiedRunResp, jobId);
      setJobStages(updatedData);
      console.log("updatedData", updatedData)
      setLoading(false);
      findResumeIndex(resp);
      return resp;
    } catch (err) {
      notify(
        true,
        "Something went wrong while fetching stage info, " + err.message,
        NOTIFICATION_TYPE.error,
        5000
      );
    }
  };

  const pollingService = async () => {
    let resp = await getJobStages();
    const inProgress = resp.some((stg) =>
      ["In Progress", "In progress"].includes(stg.stage_status)
    );
    if (inProgress && !isInputOutputVisible) {
      setTimeout(pollingService, 5000);
    }
  };

  const getJobStatastics = async () => {
    try {
      await fetchData(jobService.getJobStatsUrl(jobId));
    } catch (err) {}
  };

  useEffect(() => {
    setLoading(true);
    getJobDetail().then((resp)=>{
    getJobStages(resp);
    });
    getJobStatastics();
  }, []);

  const handleToggleInputOutput = () => {
    setIsInputOutputVisible((pre) => !pre);
  };

  const handleJobReTrigger = async (step) => {
    setLoading(true);
    try {
      const resp = await jobService.startSpecificStageOfJob(jobId, step);
      getJobStages();
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    } finally {
      setLoading(false);
    }
  };

  const refreshProject = async () => {
    setRefreshing(true);
    await getJobDetail();
    await getJobStages();
    setRefreshing(false);
    notify(true, "Project Refreshed", NOTIFICATION_TYPE.info, 5000);
  };

  const handleChangeBlockDiagram = (index) => {
    setCurrentStepDigrams(index);
  };

  const handleResume = async () => {
    setResumeLoading(true);
    try {
      await jobService.resumeJobById(jobId);
      await getJobStages();
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    } finally {
      setResumeLoading(false);
    }
  };

  const onProjectInputDownload = async () => {
    setInputDownloading(true);
    try {
      const { data, fileName } = await jobService.getJobInput(jobId);
      AppUtils.downloadFile(data, fileName);
      notify(
        true,
        "Source downloaded successfully!",
        NOTIFICATION_TYPE.success,
        5000
      );
    } catch (e) {
      notify(
        true,
        "Failed to download the project. Please try again later.",
        NOTIFICATION_TYPE.error,
        5000
      );
      console.error(e);
    } finally {
      setInputDownloading(false);
    }
  };

  const onProjectOutputDownload = async () => {
    setOutputDownloading(true);
    try {
      const { data, fileName } = await jobService.getJobOutput(jobId);
      AppUtils.downloadFile(data, fileName);
      notify(
        true,
        "Project downloaded successfully!",
        NOTIFICATION_TYPE.success,
        5000
      );
    } catch (e) {
      notify(
        true,
        "Failed to download the project. Please try again later.",
        NOTIFICATION_TYPE.error,
        5000
      );
      console.error(e);
    } finally {
      setOutputDownloading(false);
    }
  };

  if (loading) {
    <CircularSpinner />;
  }
  const CardItems = [
    {
      label: "Flows",
      key: "total_flow_count",
      bg: "bg-theme-1/20 text-theme-1 border-theme-1/55",
      icon: <AccountTreeIcon className="scale-100" />,
    },
    {
      label: "Total Components",
      key: "total_components",
      bg: "bg-theme-2/20 text-theme-2 border-theme-2/55",
      icon: <MemoryIcon className="scale-100" />,
    },
    {
      label: "Fully Migrated",
      key: "fully_migrated",
      bg: "bg-danger/10 text-danger/40 border-danger/35",
      icon: <DeveloperBoardIcon className="scale-100" />,
    },
    {
      label: "Partially Migrated",
      key: "partially_migrated",
      bg: "bg-[#fffde6] text-[#e6d200] border-[#e6d200]",
      icon: <AvTimerIcon className="scale-100" />
    },
    {
      label: "Not Migrated",
      key: "not_migrated",
      bg: "bg-[#ffe6e9] text-[#fe4d62] border-[#fe4d62]",
      icon: <NewReleasesIcon className="scale-100" />
    },
    {
      label: "Group Activities",
      key: "total_group_activities_count",
      bg: "bg-gradient-to-tr from-[#2ef43b] to-[#3b7541]",
    },
    {
      label: "Http Activity",
      key: "total_SIM_found",
      bg: "bg-white text-secondary",
    },
    {
      label: "Total Activities",
      key: "total_activities_count",
      bg: "bg-white text-secondary",
    },
    {
      label: "Global Components",
      key: "total_global_components_count",
      bg: "bg-white text-secondary",
    },

    {
      label: "JMS Activity",
      key: "total_SIM_found",
      bg: "bg-white text-secondary",
    },
    {
      label: "SIMs",
      key: "total_SIM_found",
      bg: "bg-gradient-to-tr from-[#ffa800] to-[#ffa800]",
    },
    {
      label: "Code Found",
      key: "total_code_found",
      bg: "bg-gradient-to-tr from-[#2ef43b] to-[#3b7541]",
    },

    {
      label: "Total Functions",
      key: "total_function_found",
      bg: "bg-gradient-to-tr from-[#2ef43b] to-[#3b7541]",
    },

    {
      label: "Package found",
      key: "total_package_found",
      bg: "bg-gradient-to-tr from-[#2ef43b] to-[#3b7541]",
    },
  ];

  const handleOutputClick = (index) => {
    setActiveBtnType("OUTPUT");
    handleChangeBlockDiagram(index);
    handleToggleInputOutput();
  };

  const handleInputClick = (index) => {
    setActiveBtnType("INPUT");
    handleChangeBlockDiagram(index);
    handleToggleInputOutput();
  };
  const handleVersionChange = (version, stage) =>{
    for(let i=stage; i<=2; i++){
      stageVersions[i].selectedVersion=version;
    }
    setStageVersions(stageVersions);

    const modifiedRunResp = modifyRunsToStage(runs, version, legasyStageResp);
    setJobStages(modifiedRunResp);
  }
  const handleRerun = async (stage) => {
    notify(
      true,
      "Your request has been received. We will notify you once the rerun process is complete.",
      NOTIFICATION_TYPE.info,
      5000
    );
    const resp = await jobService.reSubmitStage(jobId, stage+1);
    refreshProject();
  }

  return (
    <div>
      <div className="mt-4 bg-white shadow-md p-4 m-6 rounded-md">
        <ActivityDetailHeader
          jobDetail={jobDetail}
          refreshProject={refreshProject}
          refreshing={refreshing}
          onOutputDownload={onProjectOutputDownload}
          inputDownloading={inputDownloading}
          outputDownloading={outputDownloading}
          onInputDownload={onProjectInputDownload}
        />
      </div>
      <div className="mt-4 px-6 ">
        {/* <SummaryCard
          label="Summary"
          items={CardItems.slice(5)}
          jobDetail={jobDetail}
        /> */}
        {/* <div className="py-4"> */}
        {loading ? (
          <StepperSkeleton />
        ) : (
          <ol
            role="list"
            className="acw adb aet afz agy cty cwq bg-white border-0 shadow-lg"
          >
            {jobStages?.map((stage, index) => (
              <StageCard
                stageCount={jobStages?.length || 0}
                key={index}
                stage={stage}
                onOutputClick={() => handleOutputClick(index)}
                onInputClick={() => handleInputClick(index)}
                onResume={handleResume}
                isResume={index === resumeIndex}
                resumeLoading={resumeLoading}
                onOutputDownload={onProjectOutputDownload}
                inputDownloading={inputDownloading}
                outputDownloading={outputDownloading}
                onInputDownload={onProjectInputDownload}
                currentStageNumber={index}
                setShowRerunDialogue={(bool, stage) => {setShowRerunDialogue(bool); setRerunStage(stage)}}
                handleVersionChange={handleVersionChange}
                versions={stageVersions[index].versions}
                selectedVersion={stageVersions[index].selectedVersion}
                target={jobDetail?.targetPlatform}
                showRerunButton={stage.stage_id !== 1} // Add this new prop
              >
                {!isInputOutputVisible && (
                  <StageInputOutput
                    type={jobStages.length - 1 === index ? "INPUT" : "OUTPUT"}
                    index={index}
                    handleClose={handleToggleInputOutput}
                    jobId={jobId}
                    jobStages={jobStages}
                    fullScreen={false}
                  />
                )}
              </StageCard>
            ))}
          </ol>
        )}
      </div>

      {!loading && !loadingStats && (
        <div className=" mt-2 px-6 grid grid-cols-1 gap-0 mb-28">
          <div className="mt-4 text-2xl text-primary">Overview</div>
          <div className="text-md text-slate-500">
            Integration process insights and activity metrics at a glance,
            highlighting different components involved and external system
            interactions.
          </div>
          <div className="rounded-lg p-2">
            <div>
              <div class="rounded-lg bg-white shadow-md p-4">
                <div class="box-body">
                  <div class="flex flex-wrap mb-0 list-none justify-around text-center gap-2">
                    {CardItems.slice(0, 5).map((item, index,) => (
                      <MetricsCard
                        icon={item.icon}
                        key={index}
                        data={jobDetail?.[item.key]}
                        title={item.label}
                        bg={item.bg}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ActivitiesAndFlows data={data} />

          {/* <ActivitiesTypesCount
            data={data}
            loading={loadingStats}
            error={!!error}
          /> */}

          {/* </div> */}
          {isInputOutputVisible && (
            <StageInputOutput
              type={activeBtnType}
              index={currentStepDigrams}
              handleClose={handleToggleInputOutput}
              jobId={jobId}
              jobStages={jobStages}
            />
          )}
        </div>
      )}

<Dialog
        open={showRerunDialogue}
        onClose={()=>{setShowRerunDialogue(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to re-execute this stage?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This action will re-execute this stage and all subsequent stages using the updated versions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setShowRerunDialogue(false)}}>Disagree</Button>
          <Button onClick={()=>{setShowRerunDialogue(false); handleRerun(rerunStage)}} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JobDetail;