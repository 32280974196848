import { AppConstants } from '../utils/constants';
import axiosInstance from './AxiosService';

class UserService {
    constructor() {
      this.BASE_URL =
        'https://ship-ai-queue.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io';
    }

    async getAllUsers(sortBy = 'user_id', order = 'asc') {
      const url = `${this.BASE_URL}/users?sort_by=${sortBy}&order=${order}`;
      console.log("Making request to:", url);
      console.log("Request Headers:", axiosInstance.defaults.headers);
  
      const response = await axiosInstance.get(url);
      console.log("Response:", response); 
      return response.data.records;
  }

  async getAllRoles() {
    const url = `${this.BASE_URL}/roles`;
    console.log("Making request to:", url);
    console.log("Request Headers:", axiosInstance.defaults.headers);

    const response = await axiosInstance.get(url);
    console.log("Response:", response); 
    return response.data.records;
}
  
    }
    export default UserService;